import React, { useState, createContext } from "react"
import { User } from "../api/User"

export interface UserAuth {
  user_: any
}
const AuthContext = createContext<Partial<UserAuth>>({})

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user_, setUser] = useState<User>()
  const [accessToken, setAccessToken] = useState()

  const init = {
    user_: [user_, setUser],
    accessToken: [accessToken, setAccessToken],
  }

  return <AuthContext.Provider value={init}>{children}</AuthContext.Provider>
}

export default AuthContext

export { AuthContextProvider }
