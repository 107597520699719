import React from "react"
import { Provider as EufemiaProvider } from "@dnb/eufemia/shared"
import { QueryClient, QueryClientProvider } from "react-query"
import { AuthContextProvider } from "../../auth/context"

const queryClient = new QueryClient()

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
  console.log(`Context`)
  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <EufemiaProvider value={{ Input: { size: `medium` } }}>{element}</EufemiaProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  )
}
